<template>
  <div>
    <vx-card class="mb-4" title="Bordereaux de commissions">
      <div slot="no-body">
        <div class="aps-benin-content">
          <div class="pt-2 pb-4 aps-benin-filter">
            <div class="pt-2 pb-2">
              <vs-collapse>
                <vs-collapse-item class="aps-benin-collapse-item">
                  <div slot="header">
                    <div class="aps-benin-collapse-filter">Filtrage</div>
                  </div>
                  <div class="p-2">
                    <vs-row vs-w="12" vs-align="center">
                      <vs-col
                        class="pr-4 mb-2 mt-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <span class="pl-2">Type du bordereau</span>
                          <multiselect
                            v-model="slipsType"
                            :options="allSlipType"
                            :close-on-select="true"
                            placeholder="--Choisir type du bordereau--"
                            label="label"
                            track-by="label"
                            :selectLabel="global.selectLabel"
                            :selectedLabel="global.selectedLabel"
                            :deselectLabel="global.deselectLabel"
                          >
                          </multiselect>
                        </div>
                      </vs-col>
                      <vs-col
                        class="pr-4 mb-2 mt-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <!--
                          <div class="w-full">
                            <span class="pl-2">Intermédiaire</span>
                            <multiselect
                              v-model="intermediary"
                              :options="intermediariesList"
                              :multiple="true"
                              :close-on-select="false"
                              placeholder="--Choisir--"
                              label="fullname"
                              track-by="fullname"
                              v-validate="'required'"
                              name="Intermédiaire"
                              required
                              :selectLabel="global.selectLabel"
                              :selectedLabel="global.selectedLabel"
                              :deselectLabel="global.deselectLabel"
                            >
                            </multiselect>
                          </div>-->
                      </vs-col>
                    </vs-row>
                    <vs-row vs-w="12" vs-align="center" vs-justify="center">
                      <vs-col
                        class="pt-4 pb-2"
                        vs-type="flex"
                        vs-align="center"
                        vs-justify="center"
                        vs-lg="12"
                        vs-sm="12"
                        vs-xs="12"
                      >
                        <vs-button @click="resetFilter">
                          Réinitialiser
                        </vs-button>
                      </vs-col>
                    </vs-row>
                  </div>
                </vs-collapse-item>
              </vs-collapse>
            </div>
          </div>
          <div class="p-8">
            <div>
              <vs-prompt
                title="Exportation"
                class="export-options"
                @cancel="clearFields"
                @accept="exportToData"
                accept-text="Valider"
                cancel-text="Annuler"
                @close="clearFields"
                :active.sync="activePrompt"
              >
                <vs-input
                  v-model="fileName"
                  placeholder="Entrez le nom du fichier..."
                  class="w-full"
                />
                <v-select
                  placeholder="--Choisir--"
                  v-model="selectedFormat"
                  :options="formats"
                  class="my-4"
                >
                  <span slot="no-options">
                    {{ global.selectNotFoundData }}
                  </span>
                </v-select>
                <div class="flex">
                  <span class="mr-4">Largeur automatique de cellule:</span>
                  <vs-switch v-model="cellAutoWidth"
                    >Largeur automatique de cellule</vs-switch
                  >
                </div>
              </vs-prompt>
              <div
                class="
                  flex flex-wrap-reverse
                  items-center
                  data-list-btn-container
                "
              >
                <div>
                  <vs-dropdown
                    vs-trigger-click
                    class="dd-actions cursor-pointer ml-4"
                  >
                    <div
                      class="
                        p-3
                        shadow-drop
                        rounded-lg
                        d-theme-dark-bg
                        cursor-pointer
                        flex
                        items-center
                        justify-center
                        text-lg
                        font-medium
                        w-32 w-full
                      "
                    >
                      <span class="mr-6">Actions</span>
                      <feather-icon
                        icon="ChevronDownIcon"
                        svgClasses="h-4 w-4"
                      />
                    </div>
                    <vs-dropdown-menu>
                      <vs-dropdown-item @click="activePrompt = true">
                        <span class="flex items-center">
                          <feather-icon
                            icon="PrinterIcon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                          />
                          <span>Exporter</span>
                        </span>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
                <div>
                  <div>
                    <vs-dropdown
                      vs-trigger-click
                      class="dd-actions cursor-pointer ml-4"
                    >
                      <div
                        class="
                          p-3
                          shadow-drop
                          rounded-lg
                          d-theme-dark-bg
                          cursor-pointer
                          flex
                          items-center
                          justify-center
                          text-lg
                          font-medium
                          w-32 w-full
                        "
                      >
                        <span class="mr-2">Afficher {{ limit }}</span>
                        <feather-icon
                          icon="ChevronDownIcon"
                          svgClasses="h-4 w-4"
                        />
                      </div>
                      <vs-dropdown-menu>
                        <vs-dropdown-item
                          :key="i"
                          v-for="(r, i) in rowsTable"
                          @click="limit = r"
                        >
                          <span class="flex items-center">
                            <span>{{ r }}</span>
                          </span>
                        </vs-dropdown-item>
                      </vs-dropdown-menu>
                    </vs-dropdown>
                  </div>
                </div>
              </div>

              <div class="clearfix mb-3 mt-6">
                <div>
                  <div class="w-auto" v-if="loadingListing">
                    <clip-loader
                      :loading="loadingListing"
                      color="primary"
                    ></clip-loader>
                  </div>
                  <div v-else>
                    <div v-if="allSlips.list.length !== 0">
                      <vs-table
                        pagination
                        multiple
                        :data="allSlips.list"
                        v-model="slipsSelected"
                        search
                        noDataText="Aucun résultat trouvé"
                        stripe
                        :max-items="limit != 'Tout' ? limit : total"
                      >
                        <template slot="thead">
                          <vs-th>N°</vs-th>
                          <vs-th sort-key="date">Date du bordereau</vs-th>
                          <vs-th sort-key="name">Nom du bordereau</vs-th>
                          <vs-th sort-key="type">Type du bordereau</vs-th>
                          <vs-th sort-key="type">Intermédiaire</vs-th>
                          <vs-th>Actions</vs-th>
                        </template>
                        <template slot-scope="{ data }">
                          <vs-tr
                            :data="tr"
                            :key="indextr"
                            v-for="(tr, indextr) in data"
                          >
                            <vs-td :data="data[indextr].id">{{
                              indextr + 1
                            }}</vs-td>

                            <vs-td :data="data[indextr].date">{{
                              formatDate(data[indextr].date)
                            }}</vs-td>
                            <vs-td :data="data[indextr].name">{{
                              data[indextr].name
                            }}</vs-td>
                            <vs-td :data="data[indextr].type">{{
                              displayType(data[indextr].type)
                            }}</vs-td>
                            <vs-td :data="data[indextr].broker">{{
                              `${data[indextr].broker.person.firstname} ${data[indextr].broker.person.lastname} `
                            }}</vs-td>

                            <vs-td :data="data[indextr].id">
                              <div class="inline-flex" @click.stop>
                                <div>
                                  <vx-tooltip
                                    color="primary"
                                    text="Télécharger"
                                  >
                                    <div
                                      class="cursor-pointer"
                                      @click="showFileToDownload(data[indextr])"
                                    >
                                      <feather-icon
                                        icon="DownloadIcon"
                                        svgClasses="w-6 h-6"
                                      ></feather-icon>
                                    </div>
                                  </vx-tooltip>
                                </div>

                                <vx-tooltip
                                  color="primary"
                                  :title="aboutElement(data[indextr]).title"
                                  :text="aboutElement(data[indextr]).text"
                                >
                                  <div class="ml-8 cursor-pointer">
                                    <feather-icon
                                      icon="InfoIcon"
                                      svgClasses="w-6 h-6"
                                    ></feather-icon>
                                  </div>
                                </vx-tooltip>
                                <div
                                  class="ml-2"
                                  v-show="woIsAction == data[indextr].id"
                                >
                                  <clip-loader
                                    :loading="loadingRemoveSlip"
                                    :color="themeColors.primary"
                                    size="25px"
                                  ></clip-loader>
                                </div>
                              </div>
                            </vs-td>
                          </vs-tr>
                        </template>
                      </vs-table>
                      <vs-pagination
                        class="mt-6"
                        :total="allPages"
                        v-model="currentPage"
                      ></vs-pagination>
                    </div>
                    <div v-else>
                      <div class="flex items-center justify-center mt-8">
                        <strong>Aucun enregistrement</strong>
                      </div>
                    </div>
                  </div>
                </div>

                <vs-popup
                  class="holamundo"
                  title="Détails du bordereau"
                  :active.sync="popupActiveShow"
                >
                  <vs-row vs-w="12">
                    <vs-col
                      class="pr-4 mb-6"
                      vs-type="flex"
                      vs-justify="right"
                      vs-align="right"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <span><strong>Nom: </strong> {{ detail.name }}</span>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-6"
                      vs-type="flex"
                      vs-justify="right"
                      vs-align="right"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <span><strong>Type:</strong> {{ detail.type }} </span>
                    </vs-col>

                    <vs-col
                      class="pr-4 mb-6"
                      vs-type="flex"
                      vs-justify="right"
                      vs-align="right"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <span
                        ><strong>Date: </strong
                        >{{ formatDate(detail.date) }}</span
                      >
                    </vs-col>

                    <vs-col
                      class="pr-4 mb-6"
                      vs-type="flex"
                      vs-justify="right"
                      vs-align="right"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <span><strong>Fichier: </strong></span>

                      <div
                        class="mt-6 mb-3"
                        style="display: flex; flex-direction: column"
                      >
                        <div
                          v-for="(file, index) in detail.filesname"
                          :key="index"
                          class="center"
                        >
                          <div class="flex">
                            <span class="mr-2">{{ index + 1 }})</span>
                            <div v-if="file.filename && file.name" class="mb-6">
                              <b
                                @click="checkViewFile(file)"
                                class="cursor-pointer"
                                >{{ subString(file.name) }}</b
                              >
                            </div>
                            <div v-else class="mb-6">
                              <b>{{ subString(file) }}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </vs-col>
                  </vs-row>
                </vs-popup>

                <vs-popup
                  class="holamundo"
                  title="Fichiers à télécharger"
                  :active.sync="popupActiveDownload"
                >
                  <div
                    class="mt-6 mb-3"
                    style="display: flex; flex-direction: column"
                  >
                    <div
                      v-for="(file, index) in fileToDownload.filesname"
                      :key="index"
                      class="center"
                    >
                      <div class="flex">
                        <span class="mr-2">{{ index + 1 }})</span>

                        <div v-if="file.filename && file.name" class="mb-6">
                          <b
                            @click="checkViewFile(file)"
                            class="cursor-pointer"
                            >{{ subString(file.name) }}</b
                          >
                        </div>
                        <div v-else class="mb-6">
                          <b>{{ subString(file) }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </vs-popup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import vSelect from "vue-select";
import { mixin } from "@/mixins/mixin";
import moment from "@/helpers/moment";
import role from "@/config/role";
import { saveAs } from "file-saver";
import global from "@/config/global";
import Multiselect from "vue-multiselect";

export default {
  name: "ebsi-slip",
  components: {
    ClipLoader,
    vSelect,
    Multiselect,
  },
  props: { crrModuleName: String, crrModuleId: String },
  data() {
    return {
      form: {
        name: "",
        type: "",
        date: "",
        filesname: [],
      },
      formUpdated: {
        name: "",
        type: "",
        date: "",
        filesname: [],
      },
      detail: {
        name: "",
        type: "",
        date: "",
        filesname: [],
      },
      isResetFilter: false,
      slipsType: "",
      resetFilesUpload: "",
      fileToDownload: [],
      role: {},
      global: {},
      loadingListing: false,
      fileData: [],
      fileDataUp: [],
      loadingRemoveSlip: false,

      popupActiveShow: false,
      loading: false,
      loadingDownloadFile: false,
      loadingAddSlip: false,
      loadingUpdatedSlip: false,
      //export section
      fileName: "",
      formats: ["xlsx", "csv", "txt"],//formats: ["xlsx", "csv", "txt", "pdf"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: [
        "N°",
        "Date du bordereau",
        "Nom du bordereau",
        " Type du bordereau",
      ],
      headerVal: ["index", "date", "name", "type"],
      activePrompt: false,
      //end export section
      popupAddSlipActive: false,
      popupUpdatedSlipActive: false,
      popupActiveDownload: false,
      allBordereauFilter: [{ id: "Tout", label: "Tout" }],
      allSlipType: [
        { id: "check", label: "Chèque" },
        { id: "commission", label: "Commission" },
        { id: "company", label: "Compagnie" },
        { id: "comptable", label: "Comptable" },
        { id: "emission", label: "Emission" },
        { id: "encaissement", label: "Encaissement" },
        { id: "partenaire", label: "Partenaire" },
        { id: "production", label: "Production" },
      ],
      crrSlip: "",
      allBordereau: [],
      displayFileName: false,
      formError: false,
      slipsSelected: [],
      woIsAction: "",
      crrIDs: [],
      crrID: "",
      themeColors: {},
      formData: [],
      formDataUpdated: [],
      //Pagination
      limit: 1,
      skip: 0,
      currentPage: 1,
      total: 0,
      allPages: 1,
      //Pagination
    };
  },
  computed: {
    ...mapGetters("general", ["rowsTable"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("slips", ["allSlips"]),

    validateFormAddSlip() {
      return (
        !this.errors.any() &&
        this.form.name != "" &&
        this.form.type != "" &&
        this.form.date != "" &&
        this.form.filesname != ""
      );
    },
    validateFormUpdatedSlip() {
      return (
        !this.errors.any() &&
        this.formUpdated.name != "" &&
        this.formUpdated.type != "" &&
        this.formUpdated.date != "" &&
        this.formUpdated.filesname != ""
      );
    },
  },
  mounted() {
    this.limit = this.rowsTable[1];
    this.fetchAllModuleAdministration(["BordereauType"]);
  },

  methods: {
    ...mapActions("general", ["applyUploadFile"]),
    ...mapActions("administration", ["applyGetModuleAdministrationData"]),
    ...mapActions("slips", ["applyGetAllSlips"]),
    clearFields() {
      (this.filesname = ""),
      (this.fileName = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },
    resetFilter() {
      this.isResetFilter = true;
      this.slipsType = "";
      this.isResetFilter = false;
      let ids = [];
      this.fetchAllSlips({
        limit: this.limit,
        skip: this.skip,
        isProjectPending: true,
        broker: this.user.id,
        type: this.slipsType.id ? this.slipsType.id : "",
      });
    },
    acceptAlert(color, title, text) {
      this.$vs.notify({ color, title, text });
    },
    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },
    showFileData(data) {
      if (data == "") {
        return "";
      } else {
        return `${this.$config.serverURL}/downloads/${data}`;
      }
    },
    lastLogin(data) {
      return mixin.methods.lastLogin(data.lastLoginAt);
    },
    displayType(data) {
      const type = this.allSlipType.find((val) => val.id === data);
      return type.label;
    },
    dateElement(data) {
      return mixin.methods.dateElement(data);
    },
    formatDate(data) {
      return mixin.methods.formatDate(data);
    },
    async fetchAllModuleAdministration(modulesName) {
      this.loading = true;

      try {
        let promises = [];
        let _this = this;

        modulesName.forEach(function (name, index) {
          promises.push(
            (async function (name) {
              let res = await _this.applyGetModuleAdministrationData({
                limit: "Tout",
                skip: 0,
                crrModel: name,
                administration: _this.user.society.administration,
              });

              if (name == "BordereauType") {
                res.data.list.forEach((el) => {
                  _this.allSlipType.push({ id: el.id, label: el.name });
                  _this.allBordereauFilter.push({ id: el.id, label: el.name });
                });
              }
            })(name)
          );
        });
        await Promise.all(promises);

        this.loading = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                default:
                  this.acceptAlert(
                    "danger",
                    "Administration",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },

    showFileToDownload(data) {
      this.fileToDownload = {
        filesname: data.filesname,
      };

      this.popupActiveDownload = true;
    },

    downloadFile(data) {
      this.loadingDownloadFile = true;
      try {
        var FileSaver = require("file-saver");
        FileSaver.saveAs(
          `${this.$config.serverURL}/downloads/${data.filename}`,
          `${data.name}`
        );
        this.loadingDownloadFile = false;
      } catch (error) {
        this.loadingDownloadFile = false;
      }

      this.popupActiveDownload = true;
    },

    exportToData() {
      if (this.selectedFormat == "pdf") {
        let tableToPrintPdf = [];
        let _this = this;

        this.allSlips.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            date: _this.dateElement(el).createdAtInfo,
            name: el.name,
            type: _this.allSlipType.find((ele) => ele.id == el.type).label,
          };
          tableToPrintPdf.push(jsonTmp);
        });
        let headerArray = [];

        this.headerTitle.forEach((title, index) => {
          headerArray.push({ header: title, dataKey: this.headerVal[index] });
        });
        mixin.methods.exportToPdf(
          [...tableToPrintPdf],
          headerArray,
          this.fileName
        );
      } else {
        let tableToPrint = [];
        let _this = this;
        this.allSlips.list.forEach((el) => {
          let jsonTmp = {
            date: _this.dateElement(el).createdAtInfo,
            name: el.name,
            type: _this.allSlipType.find((ele) => ele.id == el.type).label,
            broker: `${el.broker.person.firstname} ${el.broker.person.lastname} `,
            attachFile: this.getFilesLink(el.filesname),
            infos: this.aboutElement(el).text,
          };

          tableToPrint.push(jsonTmp);
        });

        let headerTitleTmp = [
          "Date du bordereau",
          "Nom du bordereau",
          "Type du bordereau",
          "Intermédiaire",
          "Fichiers",
          "Informations",
        ];

        let headerValTmp = [
          "date",
          "name",
          "type",
          "broker",
          "attachFile",
          "infos",
        ];

        mixin.methods.exportToData(
          [...tableToPrint],
          headerTitleTmp,
          headerValTmp,
          this.fileName,
          this.cellAutoWidth,
          this.selectedFormat
        );
      }
      this.clearFields();
    },

    // fichier multiple ajout
    checkUploadFile(evt) {
      const evtLength = evt.target.files.length;
      let allFilesChecked = [];
      let formDataLength = this.formData.length;
      for (let i = 0; i < evtLength; i++) {
        let formDataTmp = new FormData();
        formDataTmp.append("uri", evt.target.files[i]);
        this.formData[formDataLength + 1] = formDataTmp;
        this.formError = false;
        allFilesChecked.push(evt.target.files[i].name);
        formDataLength++;
      }
      if (this.form.filesname) {
        this.form.filesname = [...this.form.filesname, ...allFilesChecked];
      } else {
        this.form.filesname = [...allFilesChecked];
      }
    },
    // fichier multiple modifier
    checkUploadFileUpdated(evt) {
      const evtLength = evt.target.files.length;
      let allFilesChecked = [];
      let formDataLength = this.formDataUpdated.length;
      for (let i = 0; i < evtLength; i++) {
        let formDataTmp = new FormData();
        formDataTmp.append("uri", evt.target.files[i]);
        this.formDataUpdated[formDataLength + 1] = formDataTmp;
        this.formError = false;
        allFilesChecked.push(evt.target.files[i].name);
        formDataLength++;
      }
      if (this.formUpdated.filesname) {
        this.formUpdated.filesname = [
          ...this.formUpdated.filesname,
          ...allFilesChecked,
        ];
      } else {
        this.formUpdated.filesname = [...allFilesChecked];
      }
    },
    removeFile(data) {
      this.formData.splice(data, 1);
      this.form.filesname.splice(data, 1);
    },
    removeFileUpdated(data) {
      this.formDataUpdated.splice(data, 1);
      this.formUpdated.filesname.splice(data, 1);
    },
    checkViewFile(item) {
      window.open(this.showFileData(item.filename), "_blank");
    },
    getFilesLink(data) {
      return data
        .map((acc) => `${acc.name}(${this.showFileData(acc.filename)})`)
        .join(", ");
    },

    subString(data) {
      if (data.length > 40) {
        return `${data.substring(0, 40)} ...`;
      } else {
        return data;
      }
    },

    //listing
    async fetchAllSlips(item) {
      this.loadingListing = true;
      try {
        await this.applyGetAllSlips(item);

        this.total = this.allSlips.total;
        let p = parseInt((this.total / this.limit).toFixed(0), 10);
        this.allPages = p ? p : 1;

        this.loadingListing = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loadingListing = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                default:
                  this.acceptAlert(
                    "danger",
                    "Mise à jour",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    //detail bordereaux
    checkViewSlip(data) {
      this.detail = {
        name: data.name,
        type: data.type,
        date: data.date,
        filesname: data.filesname,
      };
      this.popupActiveShow = true;
    },
  },
  watch: {
    slipsType() {
      let ids = [];
      if (!this.isResetFilter) {
        this.fetchAllSlips({
          limit: this.limit,
          skip: this.skip,
          itemId: this.crrModuleId,
          crrModel: this.crrModuleName,
          broker: this.user.id,
          type: this.slipsType && this.slipsType.id ? this.slipsType.id : "",
        });
      }
    },
    currentPage() {
      this.skip = (this.currentPage - 1) * this.limit;

      this.fetchAllSlips({
        limit: this.limit,
        skip: this.skip,
        type: this.crrSlip != undefined ? this.crrSlip.id : "",
        itemId: this.crrModuleId,
        crrModel: this.crrModuleName,
        broker: this.user.id,
      });
    },

    limit() {
      this.skip = 0;

      this.fetchAllSlips({
        limit: this.limit,
        skip: this.skip,
        type: this.crrSlip != undefined ? this.crrSlip.id : "",
        itemId: this.crrModuleId,
        crrModel: this.crrModuleName,
        broker: this.user.id,
      });
    },
    crrSlip() {
      this.fetchAllSlips({
        limit: this.limit,
        skip: this.skip,
        type: this.crrSlip != undefined ? this.crrSlip.id : "",
        itemId: this.crrModuleId,
        crrModel: this.crrModuleName,
        broker: this.user.id,
      });
    },
  },
  created() {
    this.role = role;
    this.global = global;
    Validator.localize("fr", VeeValidateFR);
  },
};
</script>

<style lang="scss">
.con-pagination-table {
  display: none;
}
</style>
